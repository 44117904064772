import React from "react";
import { NavLink } from "react-router-dom";
import configuration from "react-global-configuration";

const ProfileSideBar = (props) => {
  return (
    <>
      <section className="other_page_layouts">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3">
              <div className="new-tabs-sec">
                <ul role="new-tablist" className="new-tablist">
                  <NavLink
                    to="/account/profile"
                    className="tab-control"
                    activeClassName="active"
                  >
                    <li className="control-item">
                      <div>
                        <h6 className="tab-title">Profile</h6>
                      </div>
                    </li>
                  </NavLink>
                  <NavLink
                    to="/account/wallet"
                    className="tab-control"
                    activeClassName="active"
                  >
                    <li className="control-item">
                      <div>
                        <h6 className="tab-title">Wallet</h6>
                      </div>
                    </li>
                  </NavLink>
                  {/* <NavLink
                    to="/account/my-subscriptions"
                    className="tab-control"
                    activeClassName="active"
                  >
                    <li className="control-item">
                      <div>
                        <h6 className="tab-title">My Subscriptions</h6>
                      </div>
                    </li>
                  </NavLink> */}
                  {/* <NavLink
                    to="/account/own-projects"
                    className="tab-control"
                    activeClassName="active"
                  >
                    <li className="control-item">
                      <div>
                        <h6 className="tab-title">Your Projects</h6>
                      </div>
                    </li>
                  </NavLink> */}
                  {configuration.get("configData.is_kyc_enabled") == 1 && (
                      <NavLink
                      to="/account/kyc-verification"
                      className="tab-control"
                      activeClassName="active"
                    >
                      <li className="control-item">
                        <div>
                          <h6 className="tab-title">KYC</h6>
                        </div>
                      </li>
                    </NavLink>
                  )}
                  <NavLink
                    to="/account/invested-projects"
                    className="tab-control"
                    activeClassName="active"
                  >
                    <li className="control-item">
                      <div>
                        <h6 className="tab-title">Transaction History</h6>
                      </div>
                    </li>
                  </NavLink>
                </ul>
                <div className="new-tab-content">{props.tab}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfileSideBar;
