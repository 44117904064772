import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SvgIcon from "../SvgIcons/SvgIcon";
import { fetchProjectAllStart } from "../store/actions/ProjectActions";
import Web3 from "web3";
import configuration from "react-global-configuration";
import { formatEther } from "@ethersproject/units";
import Countdown from "react-countdown";

const Projects = (props) => {
  const comingSoon = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  useEffect(() => {
    props.dispatch(fetchProjectAllStart());
    window.web3 = new Web3(window.ethereum);
  }, []);

  return (
    <>
      <div className="other_page_layouts projects-new-card-sec">
        <section className="main-content-wrapper">
          <div className="container">
            <div className=" w-100 no-margin project-card-sec">
              {props.projects.loading ? (
                "loading"
              ) : (
                <>
                  {props.projects.data.opened_projects.length > 0 && (
                    <>
                      <header className="crumina-module crumina-heading heading--h1 heading--with-decoration align-flex-center">
                        <h1 className="medium-font-size weight-normal no-margin c-primary text-uppercase text-center ">
                          Projects Opened
                        </h1>
                      </header>
                      <div className="col-md-12 col-lg-12 no-padding medium-padding40">
                        <div className="row no-margin">
                          {props.projects.data.opened_projects.map(
                            (project, index) => (
                              <div
                                className="col-md-6 col-lg-4 col-sm-12 col-lg-12 no-padding d-flex"
                                key={index}
                              >
                                <div className="projectsWrapper">
                                  <div className="card-header-new">
                                    <Link
                                      to={`/single-project/${project.project_unique_id}`}
                                    >
                                      <div className="project-picture-img-sec">
                                        <img
                                          src={project.picture}
                                          alt={project.name}
                                        />
                                      </div>
                                    </Link>
                                    <div className="social-contents">
                                      <Link
                                        to={`/single-project/${project.project_unique_id}`}
                                      >
                                        <h4 className="text-center">
                                          {project.name}
                                          <span className="sub-text">
                                            {project.sub_text}
                                          </span>
                                        </h4>
                                      </Link>
                                    </div>
                                    {/* <div className="arrow">
                                      <SvgIcon
                                        id="icon-arrow-up"
                                        styles="woox-icon icon-up"
                                        hasPath={false}
                                      ></SvgIcon>
                                      <SvgIcon
                                        id="icon-arrow-down"
                                        styles="woox-icon icon-down"
                                        hasPath={false}
                                      ></SvgIcon>
                                    </div> */}
                                    <div className="project-status-card">
                                      <div class="statusWrapper open">
                                        <div class="dot open"></div>
                                        <p class="mb-0 text-capitalize color-green">Open</p>
                                      </div>
                                    </div>
                                    <div className="project-verification-badge-sec">
                                      {project.kyc_link && (
                                        <a target="_blank" href={project.kyc_link} className="verification-kyc-btn">KYC</a>
                                      )}
                                      {project.audited_link && (
                                        <a target="_blank" href={project.audited_link} className="audited-btn">Audited</a>
                                      )}
                                    </div>
                                  </div>
                                  <div className="social-icons">
                                    {project.facebook_link && (
                                      <a
                                        target="_blank"
                                        href={project.facebook_link}
                                      >
                                        <i className="fab fa-facebook woox-icon"></i>
                                      </a>
                                    )}
                                    {project.telegram_link && (
                                      <a
                                        target="_blank"
                                        href={project.telegram_link}
                                      >
                                        <i className="fab fa-telegram woox-icon"></i>
                                      </a>
                                    )}
                                    {project.twitter_link && (
                                      <a
                                        target="_blank"
                                        href={project.twitter_link}
                                      >
                                        <i className="fab fa-twitter woox-icon"></i>
                                      </a>
                                    )}
                                    {project.website && (
                                      <a target="_blank" href={project.website}>
                                        <i className="fab fa-dribbble woox-icon"></i>
                                      </a>
                                    )}
                                    {project.medium_link && (
                                      <a
                                        target="_blank"
                                        href={project.medium_link}
                                      >
                                        <i className="fab fa-medium woox-icon"></i>
                                      </a>
                                    )}
                                  </div>
                                  <Link
                                    to={`/single-project/${project.project_unique_id}`}
                                    className="card-body"
                                  >
                                    {/* <p className="info-text common-margin">
                                      {project.description}
                                    </p> */}
                                    <div className="swap-infos common-margin">
                                      <div>
                                        <p className="text-capitalize">
                                          exchange rate
                                        </p>
                                        {project.project_type == 2 ? (
                                          <>
                                            <p className="theme-blue highligttext">
                                              1 {project.token_symbol} ={" "}
                                              {project.usdex_exchange_rate}{" "}
                                              {configuration.get(
                                                "configData.currency"
                                              )}
                                            </p>
                                            <p className="theme-blue highligttext">
                                              1 {project.dexshare_token_symbol} ={" "}
                                              {project.dexshare_exchange_rate}{" "}
                                              {configuration.get(
                                                "configData.currency"
                                              )}
                                            </p>
                                          </>
                                        ) : (
                                          <p className="theme-blue highligttext">
                                            1 {project.token_symbol} ={" "}
                                            {project.exchange_rate}{" "}
                                            {configuration.get(
                                              "configData.currency"
                                            )}
                                          </p>
                                        )}
                                      </div>
                                      <div className="flex-center">
                                        <p className="text-capitalize">
                                          symbol
                                        </p>
                                        <p className="theme-blue highligttext">
                                          {project.token_symbol}{project.project_type == 2 ? `,${project.dexshare_token_symbol}` : ''}
                                        </p>
                                      </div>
                                      {/* <div className="flex-end">
                                        <p className="text-capitalize">
                                          access
                                        </p>
                                        <p className="theme-blue highligttext text-capitalize">
                                          {project.access_type}
                                        </p>
                                      </div> */}
                                    </div>
                                    <div className="project-progessbar">
                                      <div className="crumina-module crumina-skills-item skills-item--bordered no-margin">
                                        <div className="skills-item-info">
                                          <span className="progressbar-title">
                                            Progress
                                          </span>
                                        </div>
                                        <div className="skills-item-meter">
                                          <span
                                            className="skills-item-meter-active bg-primary-color"
                                            style={{
                                              width: `${
                                                (formatEther(
                                                  project.total_tokens_purchased
                                                ) /
                                                  project.ido_tokens) *
                                                  100 >
                                                100
                                                  ? 100
                                                  : (formatEther(
                                                      project.total_tokens_purchased
                                                    ) /
                                                      project.ido_tokens) *
                                                    100
                                              }%`,
                                            }}
                                          ></span>
                                        </div>
                                        <div className="project-progress-status">
                                          <span>
                                            {project.total_tokens_purchased != 0
                                              ? `${(
                                                  (formatEther(
                                                    project.total_tokens_purchased
                                                  ) /
                                                    project.ido_tokens) *
                                                  100
                                                ).toFixed(2)}%`
                                              : "0.00%"}
                                          </span>
                                          <span>
                                            {`${Number(
                                              formatEther(
                                                project.total_tokens_purchased
                                              )
                                            ).toFixed(2)}`}{" "}
                                            {/* {configuration.get(
                                              "configData.currency"
                                            )} */}
                                            USDC /{" "}
                                            {project.ido_tokens_formatted}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="project-poll-sec">
                                      <div className="project-poll-card">
                                        <div className="project-poll-timer-sec">
                                            <h5>Sale ends in:</h5>
                                            <Countdown
                                              date={
                                                project.end_time
                                              }
                                              intervalDelay={0}
                                              precision={3}
                                              renderer={(props) => (
                                                <div>
                                                  {props.days}:{props.hours}:{props.minutes}:{props.seconds}
                                                </div>
                                              )}
                                            />
                                        </div>
                                        <div className="project-view-poll-btn-sec">
                                          <Link to={`/single-project/${project.project_unique_id}`} className="btn btn--small  btn--primary ml-3 text-capitalize">View Pool</Link>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {props.projects.data.upcoming_projects.length > 0 && (
                    <>
                      <header className="crumina-module crumina-heading heading--h1 heading--with-decoration align-flex-center  medium-padding60 pb-0">
                        <h1 className="medium-font-size weight-normal no-margin c-primary text-uppercase text-center ">
                          Upcoming Projects
                        </h1>
                      </header>
                      <div className="col-md-12 col-lg-12 no-padding ">
                        <div className="row no-margin">
                          {props.projects.data.upcoming_projects.map(
                            (project, index) => (
                              <div
                                className="col-md-6 col-lg-4 col-sm-12 col-lg-12 no-padding d-flex"
                                key={index}
                              >
                                <div className="projectsWrapper">
                                  <div className="card-header-new">
                                    <Link
                                      to={`/single-project/${project.project_unique_id}`}
                                    >
                                      <div className="project-picture-img-sec">
                                        <img
                                          src={project.picture}
                                          alt={project.name}
                                        />
                                      </div>
                                    </Link>
                                    <div className="social-contents">
                                      <Link
                                        to={`/single-project/${project.project_unique_id}`}
                                      >
                                        <h4 className="text-center">
                                          {project.name}
                                          <span className="sub-text">
                                            {project.sub_text}
                                          </span>
                                        </h4>
                                      </Link>
                                    </div>
                                    {/* <div className="arrow">
                                      <SvgIcon
                                        id="icon-arrow-up"
                                        styles="woox-icon icon-up"
                                        hasPath={false}
                                      ></SvgIcon>
                                      <SvgIcon
                                        id="icon-arrow-down"
                                        styles="woox-icon icon-down"
                                        hasPath={false}
                                      ></SvgIcon>
                                    </div> */}
                                    <div className="project-status-card">
                                      <div class="statusWrapper scheduled">
                                        <div class="dot scheduled"></div>
                                        <p class="mb-0 text-capitalize color-blue">Upcoming</p>
                                      </div>
                                    </div>
                                    <div className="project-verification-badge-sec">
                                      {project.kyc_link && (
                                        <a target="_blank" href={project.kyc_link} className="verification-kyc-btn">KYC</a>
                                      )}
                                      {project.audited_link && (
                                        <a target="_blank" href={project.audited_link} className="audited-btn">Audited</a>
                                      )}
                                    </div>
                                  </div>
                                  <div className="social-icons">
                                    {project.facebook_link && (
                                      <a
                                        target="_blank"
                                        href={project.facebook_link}
                                      >
                                        <i className="fab fa-facebook woox-icon"></i>
                                      </a>
                                    )}
                                    {project.telegram_link && (
                                      <a
                                        target="_blank"
                                        href={project.telegram_link}
                                      >
                                        <i className="fab fa-telegram woox-icon"></i>
                                      </a>
                                    )}
                                    {project.twitter_link && (
                                      <a
                                        target="_blank"
                                        href={project.twitter_link}
                                      >
                                        <i className="fab fa-twitter woox-icon"></i>
                                      </a>
                                    )}
                                    {project.website && (
                                      <a target="_blank" href={project.website}>
                                        <i className="fab fa-dribbble woox-icon"></i>
                                      </a>
                                    )}
                                    {project.medium_link && (
                                      <a
                                        target="_blank"
                                        href={project.medium_link}
                                      >
                                        <i className="fab fa-medium woox-icon"></i>
                                      </a>
                                    )}
                                  </div>
                                  <Link
                                    to={`/single-project/${project.project_unique_id}`}
                                    className="card-body"
                                  >
                                    {/* <p className="info-text common-margin">
                                      {project.description}
                                    </p> */}
                                    <div className="swap-infos common-margin">
                                      <div>
                                        <p className="text-capitalize">
                                          exchange rate
                                        </p>
                                        {project.project_type == 2 ? (
                                          <>
                                            <p className="theme-blue highligttext">
                                              1 {project.token_symbol} ={" "}
                                              {project.usdex_exchange_rate}{" "}
                                              {configuration.get(
                                                "configData.currency"
                                              )}
                                            </p>
                                            <p className="theme-blue highligttext">
                                              1 {project.dexshare_token_symbol} ={" "}
                                              {project.dexshare_exchange_rate}{" "}
                                              {configuration.get(
                                                "configData.currency"
                                              )}
                                            </p>
                                          </>
                                        ) : (
                                          <p className="theme-blue highligttext">
                                            1 {project.token_symbol} ={" "}
                                            {project.exchange_rate}{" "}
                                            {configuration.get(
                                              "configData.currency"
                                            )}
                                          </p>
                                        )}
                                      </div>
                                      <div className="flex-center">
                                        <p className="text-capitalize">
                                          symbol
                                        </p>
                                        <p className="theme-blue highligttext">
                                          {project.token_symbol}{project.project_type == 2 ? `,${project.dexshare_token_symbol}` : ''}
                                        </p>
                                      </div>
                                      {/* <div className="flex-end">
                                        <p className="text-capitalize">
                                          access
                                        </p>
                                        <p className="theme-blue highligttext text-capitalize">
                                          {project.access_type}
                                        </p>
                                      </div> */}
                                    </div>
                                    <div className="project-progessbar">
                                      <div className="crumina-module crumina-skills-item skills-item--bordered no-margin">
                                        <div className="skills-item-info">
                                          <span className="progressbar-title">
                                            Progress
                                          </span>
                                        </div>
                                        <div className="skills-item-meter">
                                          <span
                                            className="skills-item-meter-active bg-primary-color"
                                            style={{
                                              width: `${
                                                (formatEther(
                                                  project.total_tokens_purchased
                                                ) /
                                                  project.ido_tokens) *
                                                  100 >
                                                100
                                                  ? 100
                                                  : (formatEther(
                                                      project.total_tokens_purchased
                                                    ) /
                                                      project.ido_tokens) *
                                                    100
                                              }%`,
                                            }}
                                          ></span>
                                        </div>
                                        <div className="project-progress-status">
                                          <span>
                                            {project.total_tokens_purchased != 0
                                              ? `${(
                                                  (formatEther(
                                                    project.total_tokens_purchased
                                                  ) /
                                                    project.ido_tokens) *
                                                  100
                                                ).toFixed(2)}%`
                                              : "0.00%"}
                                          </span>
                                          <span>
                                            {`${Number(
                                              formatEther(
                                                project.total_tokens_purchased
                                              )
                                            ).toFixed(2)}`}{" "}
                                            {/* {configuration.get(
                                              "configData.currency"
                                            )} */}
                                            USDC /{" "}
                                            {project.ido_tokens_formatted}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="project-poll-sec">
                                      <div className="project-poll-card">
                                        <div className="project-poll-timer-sec">
                                            <h5>Sale starts in:</h5>
                                            <Countdown
                                              date={
                                                project.start_time
                                              }
                                              intervalDelay={0}
                                              precision={3}
                                              renderer={(props) => (
                                                <div>
                                                  {props.days}:{props.hours}:{props.minutes}:{props.seconds}
                                                </div>
                                              )}
                                            />
                                        </div>
                                        <div className="project-view-poll-btn-sec">
                                          <Link to={`/single-project/${project.project_unique_id}`} className="btn btn--small  btn--primary ml-3 text-capitalize">View Pool</Link>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {props.projects.data.closed_projects.length > 0 && (
                    <>
                      <header className="crumina-module crumina-heading heading--h1 heading--with-decoration align-flex-center medium-padding60 pb-0">
                        <h1 className="medium-font-size weight-normal no-margin c-primary text-uppercase text-center ">
                          Projects Closed
                        </h1>
                      </header>
                      <div className="col-md-12 col-lg-12 no-padding ">
                        <div className="row no-margin">
                          {props.projects.data.closed_projects.map(
                            (project, index) => (
                              <div
                                className="col-md-6 col-lg-4 col-sm-12 col-lg-12 no-padding d-flex "
                                key={index}
                              >
                                <div className="projectsWrapper">
                                  <div className="card-header-new">
                                    <Link
                                      to={`/single-project/${project.project_unique_id}`}
                                    >
                                      <div className="project-picture-img-sec">
                                        <img
                                          src={project.picture}
                                          alt={project.name}
                                        />
                                      </div>
                                    </Link>
                                    <div className="social-contents">
                                      <Link
                                        to={`/single-project/${project.project_unique_id}`}
                                      >
                                        <h4 className="text-center">
                                          {project.name}
                                          <span className="sub-text">
                                            {project.sub_text}
                                          </span>
                                        </h4>
                                      </Link>
                                    </div>

                                    {/* <div className="arrow">
                                      <SvgIcon
                                        id="icon-arrow-up"
                                        styles="woox-icon icon-up"
                                        hasPath={false}
                                      ></SvgIcon>
                                      <SvgIcon
                                        id="icon-arrow-down"
                                        styles="woox-icon icon-down"
                                        hasPath={false}
                                      ></SvgIcon>
                                    </div> */}
                                    <div className="project-status-card">
                                      <div class="statusWrapper close">
                                        <div class="dot close"></div>
                                        <p class="mb-0 text-capitalize color-red">Closed</p>
                                      </div>
                                    </div>
                                    <div className="project-verification-badge-sec">
                                      {project.kyc_link && (
                                        <a target="_blank" href={project.kyc_link} className="verification-kyc-btn">KYC</a>
                                      )}
                                      {project.audited_link && (
                                        <a target="_blank" href={project.audited_link} className="audited-btn">Audited</a>
                                      )}
                                    </div>
                                  </div>
                                  <div className="social-icons">
                                    {project.facebook_link && (
                                      <a
                                        target="_blank"
                                        href={project.facebook_link}
                                      >
                                        <i className="fab fa-facebook woox-icon"></i>
                                      </a>
                                    )}
                                    {project.telegram_link && (
                                      <a
                                        target="_blank"
                                        href={project.telegram_link}
                                      >
                                        <i className="fab fa-telegram woox-icon"></i>
                                      </a>
                                    )}
                                    {project.twitter_link && (
                                      <a
                                        target="_blank"
                                        href={project.twitter_link}
                                      >
                                        <i className="fab fa-twitter woox-icon"></i>
                                      </a>
                                    )}
                                    {project.website && (
                                      <a target="_blank" href={project.website}>
                                        <i className="fab fa-dribbble woox-icon"></i>
                                      </a>
                                    )}
                                    {project.medium_link && (
                                      <a
                                        target="_blank"
                                        href={project.medium_link}
                                      >
                                        <i className="fab fa-medium woox-icon"></i>
                                      </a>
                                    )}
                                  </div>
                                  <Link
                                    to={`/single-project/${project.project_unique_id}`}
                                    className="card-body"
                                  >
                                    {/* <p className="info-text common-margin">
                                      {project.description}
                                    </p> */}
                                    <div className="swap-infos common-margin">
                                      <div>
                                        <p className="text-capitalize">
                                          exchange rate
                                        </p>
                                        {project.project_type == 2 ? (
                                          <>
                                            <p className="theme-blue highligttext">
                                              1 {project.token_symbol} ={" "}
                                              {project.usdex_exchange_rate}{" "}
                                              {configuration.get(
                                                "configData.currency"
                                              )}
                                            </p>
                                            <p className="theme-blue highligttext">
                                              1 {project.dexshare_token_symbol} ={" "}
                                              {project.dexshare_exchange_rate}{" "}
                                              {configuration.get(
                                                "configData.currency"
                                              )}
                                            </p>
                                          </>
                                        ) : (
                                          <p className="theme-blue highligttext">
                                            1 {project.token_symbol} ={" "}
                                            {project.exchange_rate}{" "}
                                            {configuration.get(
                                              "configData.currency"
                                            )}
                                          </p>
                                        )}
                                      </div>
                                      <div className="flex-center">
                                        <p className="text-capitalize">
                                          symbol
                                        </p>
                                        <p className="theme-blue highligttext">
                                          {project.token_symbol}{project.project_type == 2 ? `,${project.dexshare_token_symbol}` : ''}
                                        </p>
                                      </div>
                                      {/* <div className="flex-end">
                                        <p className="text-capitalize">
                                          access
                                        </p>
                                        <p className="theme-blue highligttext text-capitalize">
                                          {project.access_type}
                                        </p>
                                      </div> */}
                                    </div>
                                    <div className="project-progessbar">
                                      <div className="crumina-module crumina-skills-item skills-item--bordered no-margin">
                                        <div className="skills-item-info">
                                          <span className="progressbar-title">
                                            Progress
                                          </span>
                                        </div>
                                        <div className="skills-item-meter">
                                          <span
                                            className="skills-item-meter-active bg-primary-color"
                                            style={{
                                              width: `${
                                                (formatEther(
                                                  project.total_tokens_purchased
                                                ) /
                                                  project.ido_tokens) *
                                                  100 >
                                                100
                                                  ? 100
                                                  : (formatEther(
                                                      project.total_tokens_purchased
                                                    ) /
                                                      project.ido_tokens) *
                                                    100
                                              }%`,
                                            }}
                                          ></span>
                                        </div>
                                        <div className="project-progress-status">
                                          <span>
                                            {project.total_tokens_purchased != 0
                                              ? `${(
                                                  (formatEther(
                                                    project.total_tokens_purchased
                                                  ) /
                                                    project.ido_tokens) *
                                                  100
                                                ).toFixed(2)}%`
                                              : "0.00%"}
                                          </span>
                                          <span>
                                            {`${Number(
                                              formatEther(
                                                project.total_tokens_purchased
                                              )
                                            ).toFixed(2)}`}{" "}
                                            {/* {configuration.get(
                                              "configData.currency"
                                            )} */}
                                            USDC /{" "}
                                            {project.ido_tokens_formatted}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="project-poll-sec">
                                      <div className="project-poll-card">
                                        <div className="project-poll-timer-sec">
                                            {/* <h5>Sale starts in:</h5>
                                            <p>10:20:02:41</p> */}
                                        </div>
                                        <div className="project-view-poll-btn-sec">
                                          <Link to={`/single-project/${project.project_unique_id}`} className="btn btn--small  btn--primary ml-3 text-capitalize">View Pool</Link>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {props.projects.data.opened_projects.length == 0 &&
                    props.projects.data.closed_projects.length == 0 &&
                    props.projects.data.upcoming_projects.length == 0 && (
                      <>
                        <header className="crumina-module crumina-heading heading--h1 heading--with-decoration align-flex-center  medium-padding40">
                          <h1 className="medium-font-size weight-normal no-margin c-primary text-uppercase text-center ">
                            no projects, will be added soon
                          </h1>
                        </header>
                      </>
                    )}
                </>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  projects: state.projectReducer.projects,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Projects);
