import React from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";

const ClaimTokens = (props) => {
  const { claimTokensList , checkClaimTokenEligibility, claimButton, singleProject} = props;

  return (
    <>
      <div role="tabpanel" class="tab-pane fade active" id="claim-tokens">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb30">
            <table>
              <thead>
                <tr>
                  <th className="text-capitalize ">Claim Tokens</th>
                  <th className="text-capitalize text-center">Eligible Date</th>
                  <th className="text-capitalize text-center">Claim Amount</th>
                  <th className="text-capitalize text-center">Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {claimTokensList.data.total > 0 &&
                  claimTokensList.data.claim_token_transactions.map(
                    (claimToken, index) => (
                      <>
                        <tr key={index}>
                          <td className="text-capitalize ">{index + 1}</td>
                          <td className="text-capitalize text-center">
                            {claimToken.claime_eligible_date}
                          </td>
                          <td className="text-capitalize text-center">
                            {singleProject.data.project.project_type == 2 ? (
                              <>
                                <p className="m-0">
                                  ≈ {(((Number(claimToken.token_allocated) / 3) * 1) / Number(singleProject.data.project.usdex_exchange_rate)).toLocaleString(undefined, {maximumFractionDigits:4})}
                                  {" "}{singleProject.data.project.token_symbol}
                                </p>
                                <p className="m-0">
                                  ≈ {(((Number(claimToken.token_allocated) / 3) * 2) / Number(singleProject.data.project.dexshare_exchange_rate)).toLocaleString(undefined, {maximumFractionDigits:4})}
                                  {" "}{singleProject.data.project.dexshare_token_symbol}
                                </p>
                              </>
                            ) : (
                              <>
                                ≈ {Number(claimToken.token_allocated).toLocaleString(undefined, {maximumFractionDigits:4})}
                              </>
                            )}
                          </td>
                          <td className="text-capitalize text-center">
                            {claimToken.is_claimed == 0 ? "not claimed" : "claimed"}
                          </td>
                          <td className=" text-center">
                            {claimToken.is_claimed == 0 ? claimTokensList.data.next_token_claim_week == claimToken.token_claim_week && claimToken.is_eligible_to_claim == 1 ? (
                              <>
                                <button
                                  type="button"
                                  className="btn btn--small btn--primary text-capitalize claim-token-btn"
                                  onClick={() => checkClaimTokenEligibility(claimToken.token_claim_week)}
                                >
                                  {claimButton != "" ? claimButton : "Claim tokens"}
                                </button>
                              </>
                            ) : (
                              <button
                              type="button"
                              className="btn btn--small btn--primary text-capitalize claim-token-btn"
                              disabled
                            >
                              Claim tokens
                            </button>
                            ) : (
                              <span> - </span>
                            )}
                          </td>
                        </tr>
                      </>
                    )
                  )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  claimTokensList: state.projectReducer.claimTokensList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default React.memo(
  connect(mapStateToPros, mapDispatchToProps)(ClaimTokens)
);
