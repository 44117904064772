import React, { useState, useContext } from "react";
import { useEffect } from "react";
import { authContext } from "../account/auth/AuthProvider";
import { fetchSubscriptionStart } from "../store/actions/SubscriptionAction";
import { connect } from "react-redux";

const Lock = (props) => {

  const { auth } = useContext(authContext);

  const [lockAmount, setLockAmount] = useState(0);

  useEffect(() => {
    props.dispatch(fetchSubscriptionStart());
  }, []);

  const handleStakeTokens = () => {
    props.stakeTokens(lockAmount);
  };

  return (
    <>
      <div role="tabpanel" class="tab-pane fade active" id="Stack">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-7 col-lg-7 col-md-10">
                    <div className="lock-box">
                        <div className="lock-balance-amount-sec">
                            <h4>Locked :<span>{props.stakingBalance != "-" ? Number(props.stakingBalance).toLocaleString(undefined, { maximumFractionDigits: 5 }) : props.stakingBalance}</span></h4>
                        </div>
                        <div className="lock-card">
                          {props.subscriptions.loading ? null : props.subscriptions.data.length > 0 ? (
                            <div className="lock-header-sec">
                                <div className="row justify-content-center">
                                  {props.subscriptions.data.map((subscription) => (
                                    <div className="col-xl-3 col-lg-3">
                                        <div className="lock-item">
                                            <h4 className="blue-tire-color">{subscription.title}</h4>
                                            <button className="lock-btn-outline" onClick={() => setLockAmount(subscription.min_staking_balance)}>
                                              {subscription.min_staking_balance}
                                            </button>
                                        </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ) : null}
                            <div className="lock-body-sec">
                                <h3>Lock your dexIRA tokens during presale</h3>
                                <p>Tokens must be locked during presale to avoid double entry.
                                    You may unlock and claim as soon as the presale period is over 
                                </p>
                                <div className="lock-card-form">
                                    <div className="lock-form">
                                        <div className="input-group">
                                            <div className="input-group-area">
                                                <input type="number" 
                                                  placeholder="Eg: 100" 
                                                  min='0'
                                                  value={lockAmount}
                                                  onChange={(e) => setLockAmount(e.currentTarget.value)}
                                                  />
                                            </div>
                                            <button className="max-btn"
                                              onClick={(event) => { event.preventDefault(); setLockAmount(Number(auth.BUSDXTokenBalance))}}
                                            > 
                                              <div className="input-group-icon">Max</div>
                                            </button>
                                        </div>
                                        <p>Please ensure you have locked enough tokens to quality for your desired tier.
                                            If you are unsure click MAX and we will assign the largest available tier
                                        </p>
                                        <button 
                                          className="btn btn--small  btn--primary ml-3 text-capitalize" 
                                          onClick={() => handleStakeTokens()}
                                          disabled={lockAmount <= 0 || !auth.authStatus || props.stakeLoading.status ? 'true' : ''}
                                          >
                                            {props.stakeLoading.buttonContent == "" || 
                                            props.stakeLoading.buttonContent == null? 
                                            "Lock Tokens" :
                                            props.stakeLoading.buttonContent
                                            }
                                            
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  subscriptions: state.subscriptions.subscription,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Lock);