import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { authContext } from "../account/auth/AuthProvider";
import AmountToUnstake from "./unstakeComponents/AmountToUnstake";
import Checkpoint from "./unstakeComponents/Checkpoint";
import Confirmation from "./unstakeComponents/Confirmation";
import Confrim from "./unstakeComponents/Confrim";
import PreAuthorization from "./unstakeComponents/PreAuthorization";

const UnLock = (props) => {

  const { auth } = useContext(authContext);

  const [unlockAmount, setUnlockAmount] = useState(0);

	const handleUnstakeTokens = () => {
    props.unstakeToken(unlockAmount);
  };

  return (
    <>
       <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-7 col-lg-7 col-md-10">
                    <div className="lock-box">
                      <div className="lock-balance-amount-sec">
                          <h4>Locked :<span>{props.stakingBalance != "-" ? Number(props.stakingBalance).toLocaleString(undefined, { maximumFractionDigits: 5 }) : props.stakingBalance}</span></h4>
                        </div>
                        <div className="lock-card">
                            <div className="lock-body-sec">
                                <h3>Unlock your dexIRA tokens during presale</h3>
                                <div className="lock-card-form mt-4">
                                    <div className="lock-form">
                                        <div className="input-group">
                                            <div className="input-group-area">
                                                <input type="number" 
                                                  placeholder="Eg: 100" 
																									min='0'
                                                  value={unlockAmount}
                                                  onChange={(e) => setUnlockAmount(e.currentTarget.value)}/>
                                            </div>
                                            <button className="max-btn"
																							onClick={(e) => { e.preventDefault(); setUnlockAmount(Number(props.stakingBalance))}}
																						> 
                                              <div className="input-group-icon">Max</div>
                                            </button>
                                        </div>
                                        <p>If the presale periods is over you can click max and withdraw all of yours tokens
                                        </p>
                                        <button className="btn btn--small  btn--primary ml-3 text-capitalize"
																					onClick={() => handleUnstakeTokens()}
																					disabled={unlockAmount <= 0 || !auth.authStatus || props.unstakeLoading.status ? 'true' : ''}
																				>
																					{props.unstakeLoading.buttonContent == "" || 
                                            props.unstakeLoading.buttonContent == null? 
                                            "Unlock Tokens" :
                                            props.unstakeLoading.buttonContent
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default UnLock;
