import React, { useState, useEffect, useContext } from 'react';
import { authContext } from "../account/auth/AuthProvider";
import { connect } from "react-redux";
import RewardStaking from "../../abis/LockContract.json";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../Helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import { stakeTokenStart } from "../store/actions/StakeUnstakeAction";
import { formatEther } from "@ethersproject/units";

const LockModal = (props) => {

  const { status, handleLockModalClose } = props;

  const { auth } = useContext(authContext);

  const [lockAmount, setLockAmount] = useState(0);

  const [rewardStaking, setRewardStaking] = useState("");

  const [stakeLoading, setStakeLoading] = useState({
    status: false,
    buttonContent: null,
  })

  const [stakingPoolDetails, setStakingPoolDetails] = useState({
    totalstakingBalance: "-",
    stakingBalance: "-",
    rewardRate: "-",
    numberOfStakers: "-",
    busdReward: "-"
  });

  useEffect(() => {
    if (!auth.loading && auth.accounts != "") {
      getStatkingPoolData(auth.accounts);
    }
  }, [auth.loading, auth.accounts]);

  const getStatkingPoolData = async () => {
    const web3 = window.web3;

    const networkId = await web3.eth.net.getId();

    const rewardStakingPool = RewardStaking.networks[networkId];
    // if (rewardStakingPool) {
    const rewardStaking = new web3.eth.Contract(
      RewardStaking.abi,
      "0xA05CDd5fC632Eb4BAAc722C0087678bB1Ec0066D"
    );
    // console.log("Reward address", rewardStakingPool.address) 
    setRewardStaking(rewardStaking);

    console.log("name", await rewardStaking.methods.name().call());

    let stakingBalance = await rewardStaking.methods
      .balanceOf(auth.accounts)
      .call();

    console.log("Staking Balance", stakingBalance);

    let totalstakingBalance = await rewardStaking.methods
      .totalSupply()
      .call();

    console.log("Total supple", totalstakingBalance);

    let rewardRate = '0';

    // let rewardRate = await rewardStaking.methods
    //   .rewardRate()
    //   .call();

    // console.log("Reward rate", rewardRate);

    let numberOfStakers = await rewardStaking.methods
      .numberOfStakers()
      .call();

    setStakingPoolDetails({
      ...stakingPoolDetails,
      totalstakingBalance: Number(formatEther(totalstakingBalance)).toLocaleString(undefined, { maximumFractionDigits: 5 }),
      stakingBalance: stakingBalance / (10 ** 9),
      rewardRate: rewardRate,
      numberOfStakers: numberOfStakers,
    });
    console.log("Staking poll", rewardStaking._address);
    // } else {
    //   window.alert("rewardStaking contract not deployed to detected network.");
    // }
  };

  // Stake Tokens.
  const stakeTokens = (amount) => {

    console.log("staking running")

    setStakeLoading({
      ...stakeLoading,
      status: true,
      buttonContent: "Initializing Lock...",
    })

    console.log('amount ', amount)

    amount = amount * (10 ** 9);
    let _amount = amount.toString();
    // _amount = window.web3.utils.toWei(_amount, "Ether");

    console.log(amount)

    auth.BUSDXTokenData.methods
      .approve(rewardStaking._address, _amount)
      .send({ from: auth.accounts })
      .on("receipt", (receipt) => {
        console.log("receipt running")
        rewardStaking.methods
          .lockTokens(_amount)
          .send({ from: auth.accounts })
          .once("receipt", async (receipt) => {
            // API call
            props.dispatch(stakeTokenStart({ id: auth.userId, amount: lockAmount, type: 1 }))
            let notificationMessage = getSuccessNotificationMessage(
              "Lock token successfully..."
            );
            props.dispatch(createNotification(notificationMessage));
            //loadBlockchainData();
            console.log("stacked successfully")
            setStakeLoading({
              ...stakeLoading,
              status: false,
              buttonContent: null,
            })
            handleLockModalClose()
          })
          .on("error", (error) => {
            let notificationMessage;
            if (error.message == undefined) {
              notificationMessage = getErrorNotificationMessage(
                "Unexpected error occuried, Please try again..."
              );
            } else {
              notificationMessage = getErrorNotificationMessage(error.message);
            }
            props.dispatch(createNotification(notificationMessage));
            setStakeLoading({
              ...stakeLoading,
              status: false,
              buttonContent: null,
            })
          });
      })
      .on("error", (error) => {
        let notificationMessage;
        if (error.message == undefined) {
          notificationMessage = getErrorNotificationMessage(
            "Unexpected error occuried, Please try again..."
          );
        } else {
          notificationMessage = getErrorNotificationMessage(error.message);
        }
        props.dispatch(createNotification(notificationMessage));
        setStakeLoading({
          ...stakeLoading,
          status: false,
          buttonContent: null,
        })
      });
  };

  return (
    <>
      <div
        id="authModal"
        className={`overflow-y-scroll connect-wallet-modal ${status ? "show" : null
          }`}
      >
        <div className="authModalWrapper no-padding">
          <div
            className="wrapper"
            onClick={() => handleLockModalClose()}
          ></div>
          <div
            className={`modal-body connect-wallet-modal-body form-wrapper form--dark col-lg-6 col-md-5 col-xs-11 col-sm-11 ${status ? "show" : null
              }`}
          >
            <div className="outside-scroll"></div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  no-padding ">
              <div className="headerwrapper">
                <h4 className="text-center text-capitalize primary-text">
                  Lock
                </h4>
                <div
                  className="modal-close"
                  onClick={() => handleLockModalClose()}
                >
                  <svg className="woox-icon">
                    <use xlinkHref="#icon-error-circle"></use>
                  </svg>
                </div>
              </div>
              <div className="lock-box new-lock-box">
                <div className="lock-card">
                  <div className="lock-body-sec">
                    <h3>Lock your dexIRA tokens during presale</h3>
                    <p>Tokens must be locked during presale to avoid double entry.
                      You may unlock and claim as soon as the presale period is over
                    </p>
                    <div className="lock-card-form">
                      <div className="lock-form">
                        <div className="input-group">
                          <div className="input-group-area">
                            <input type="number"
                              placeholder="Eg: 100"
                              min='0'
                              value={lockAmount}
                              onChange={(e) => setLockAmount(e.currentTarget.value)}
                            />
                          </div>
                          <button className="max-btn"
                            onClick={(event) => { event.preventDefault(); setLockAmount(Number(auth.BUSDXTokenBalance))}}
                          >
                            <div className="input-group-icon">Max</div>
                          </button>
                        </div>
                        <p>Please ensure you have locked enough tokens to quality for your desired tier.
                          If you are unsure click MAX and we will assign the largest available tier
                        </p>
                        <button
                          className="btn btn--small  btn--primary ml-3 text-capitalize"
                          onClick={() => stakeTokens(lockAmount)}
                          disabled={lockAmount <= 0 || !auth.authStatus || stakeLoading.status ? 'true' : ''}
                        >
                          {stakeLoading.buttonContent == "" || 
                            stakeLoading.buttonContent == null? 
                            "Lock Tokens" :
                            stakeLoading.buttonContent
                          }
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(LockModal);
